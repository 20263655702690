
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useReportApi } from '../../resources/hooks/api/useReportApiHook';
import Select from 'react-select';
import toast from 'react-hot-toast';
import "react-datepicker/dist/react-datepicker.css";
import { MONTHS } from '../../resources/constants';
import { lastYears } from '../../resources/functions';
import { currencyFormat } from '../../resources/helper';
import { fileDownloadBlob, usdCurrencyFormat } from '../../resources/functions'
import {getBranch} from '../../store/user/selectors'
import moment from 'moment';

interface Props {

}

const BalanceSheet: React.FC<Props> = () => {
    const { balanceSheetData, balanceSheetRefresh, downloadReport } = useReportApi();

    const [glAllAccount, setGlAllAccount] = useState<any[]>([]);
    const [glBalanceSheetList, setGlBalanceSheetList] = useState<any[]>([]);
    const [searchParams, setSearchParams] = useState<any>({
        month: { value: '01', label: 'January' },
        year: { value: new Date().getFullYear(), label: '' + new Date().getFullYear() }
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const branchData = useSelector(getBranch)


    const fetchBalanceSheetList = () => {
        const params: any = {
            "page": 1,
            "per_page": 5000,
            year: searchParams.year['value'],
            month: searchParams.month['value'],
            branch_id: +branchData['id']
        }

        setIsLoading(true);
        balanceSheetData(params, (message: string, resp: any) => {
            setIsLoading(false);
            if (resp && resp['data'] && resp['data']['data'] && resp['data']['data']['result']) {
                setGlBalanceSheetList(resp.data.data.result);
            } else {
                setGlBalanceSheetList([]);
            }
        }, (message: string, resp: any) => {
            setGlBalanceSheetList([]);
            setIsLoading(false);
            toast.error(message);
        })
    }

    const refreshBalanceSheetList = () => {
        const params: any = {
            "page": 1,
            "per_page": 5000,
            year: searchParams.year['value'],
            month: searchParams.month['value'],
            branch_id: +branchData['id']
        }

        setIsLoading(true);
        balanceSheetRefresh(params, (message: string, resp: any) => {
            setIsLoading(false);
            if (resp && resp['data'] && resp['data']['data'] && resp['data']['data']['result']) {
                setGlBalanceSheetList(resp.data.data.result);
            } else {
                setGlBalanceSheetList([]);
            }
        }, (message: string, resp: any) => {
            setGlBalanceSheetList([]);
            setIsLoading(false);
            toast.error(message);
        })
    }

    const _downloadReport = () => {
        const params: any = {
            year: searchParams.year['value'],
            month: +searchParams.month['value'],
            branch_id: +branchData['id'],
            type: 1 // for profit/loss
        }

        setIsLoading(true);
        downloadReport(params, (message: string, resp: any) => {
            setIsLoading(false);
            fileDownloadBlob(resp, `bs-${searchParams.year['value']}-${searchParams.month['value']}`)
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }

    const _handleChangeSearchParam = (e: any, type: string) => {
        if (type === 'month') {
            setSearchParams({
                ...searchParams,
                'month': e
            })
        } else if (type === 'year') {
            setSearchParams({
                ...searchParams,
                'year': e
            })
        }
    }

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Balance Sheet</h1>
                        </div>
                    </div>
                </div>
                <div className="filter-box-top">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="filter-wrap">
                                <div className="filter-box">
                                    <Select
                                        onChange={(e: any) => _handleChangeSearchParam(e, 'month')}
                                        options={MONTHS}
                                        defaultValue={searchParams['month']}
                                        className={'zindex-10'}
                                    />
                                </div>
                                <div className="filter-box">
                                    <Select
                                        onChange={(e: any) => _handleChangeSearchParam(e, 'year')}
                                        options={lastYears(5)}
                                        defaultValue={searchParams['year']}
                                        className={'zindex-10'}
                                    />
                                </div>

                                <div className="filter-box">
                                    <button type="button" onClick={fetchBalanceSheetList} className="btn-primary btn btn-lg">Generate Report &nbsp; <i className="fa fa-search" aria-hidden="true"></i></button> &nbsp;
                                    {
                                        glBalanceSheetList.length ? (
                                            <button type="button" onClick={refreshBalanceSheetList} className="btn-warning btn btn-lg">Refresh &nbsp; <i className="fa fa-refresh" aria-hidden="true"></i></button>
                                        ) : <></>
                                    }
                                </div>
                                {
                                    glBalanceSheetList.length ? (
                                        <div className="filter-box text-end">
                                            <button type="button" onClick={_downloadReport} className="btn-dark btn btn-lg">
                                                Export PDF &nbsp;
                                                <i className="fa fa-download" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    ) : <></>
                                }
                                
                                <div className="filter-btn">
                                    <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="executive-dashboard table-design">
                    {
                        glBalanceSheetList.length ? (
                            <div className='row'>
                                <div className="balance-sheet-table-header col-md-12">
                                    <div className="col-md-4"><h2></h2></div>
                                    <div className="col-md-4">
                                        {/* <h2>Month ending</h2> */}
                                        <h2>Current Month</h2>
                                        <h2>
                                        { moment(`${+searchParams['year']['value']}-${searchParams['month']['value']}-01`).endOf('month').format('MM/DD/YYYY')}
                                    </h2></div>
                                    <div className="col-md-4">
                                        {/* <h2>Prior Year Month ending</h2> */}
                                        <h2>Current Month Prior Year</h2>
                                        <h2>
                                        { moment(`${+searchParams['year']['value'] - 1}-${searchParams['month']['value']}-01`).endOf('month').format('MM/DD/YYYY')}
                                    </h2></div>
                                </div>
                                <div className="balance-sheet-table-body col-md-12">
                                    {
                                        glBalanceSheetList.map((data, index) => {

                                            const { report_code } = data['account_chart'];
                                            const shouldHideRow = ['VP', 'VP/IP', 'IP'].includes(report_code) && !data['prev_year_total_amount'] && !data['total_amount'] ? 'd-none' : '';
                                            if(shouldHideRow == ''){
                                                return (
                                                    <div className={`bs-table-body-row bs-table-body-${data['account_chart']['report_code']} ${shouldHideRow}`} key={index}>
                                                        <div className="col-md-4">
                                                            <h4> {data['account_chart']['name']}</h4>
                                                        </div>
                                                        {
                                                            !['GH', 'SH', 'SSH'].includes(data['account_chart']['report_code']) && (
                                                                <>
                                                                    <div className="col-md-4 d-flex">
                                                                        <div className="col-md-3">
                                                                            {
                                                                                ['VP', 'VP/IP', 'IP'].includes(data['account_chart']['report_code']) && (
                                                                                    <h4>{data['total_amount'] >= 0 ? usdCurrencyFormat(data['total_amount'] || 0) : `(${usdCurrencyFormat(Math.abs(data['total_amount']))})`}</h4>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            {
                                                                                data['account_chart']['report_code'] === 'SST' && (
                                                                                    <h4 className={data['account_chart']['report_code'] + '-sum'}>{data['total_amount'] >= 0 ? usdCurrencyFormat(data['total_amount'] || 0) : `(${usdCurrencyFormat(Math.abs(data['total_amount']))})`}</h4>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            {
                                                                                data['account_chart']['report_code'] === 'ST' && (
                                                                                    <h4 className={data['account_chart']['report_code'] + '-sum'}>{data['total_amount'] >= 0 ? usdCurrencyFormat(data['total_amount'] || 0) : `(${usdCurrencyFormat(Math.abs(data['total_amount']))})`}</h4>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            {
                                                                                data['account_chart']['report_code'] === 'GT' && (
                                                                                    <h4 className={data['account_chart']['report_code'] + '-sum'}>{data['total_amount'] >= 0 ? usdCurrencyFormat(data['total_amount'] || 0) : `(${usdCurrencyFormat(Math.abs(data['total_amount']))})`}</h4>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 d-flex">
                                                                        <div className="col-md-3">
                                                                            {
                                                                                ['VP', 'VP/IP', 'IP'].includes(data['account_chart']['report_code']) && (
                                                                                    <h4>{data['prev_year_total_amount'] >= 0 ? usdCurrencyFormat(data['prev_year_total_amount'] || 0) : `(${usdCurrencyFormat(Math.abs(data['prev_year_total_amount']))})`}</h4>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            {
                                                                                data['account_chart']['report_code'] === 'SST' && (
                                                                                    <h4 className={data['account_chart']['report_code'] + '-sum'}>
                                                                                        {data['prev_year_total_amount'] >= 0 ? usdCurrencyFormat(data['prev_year_total_amount'] || 0) : `(${usdCurrencyFormat(Math.abs(data['prev_year_total_amount']))})`}
                                                                                        </h4>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            {
                                                                                data['account_chart']['report_code'] === 'ST' && (
                                                                                    <h4 className={data['account_chart']['report_code'] + '-sum'}>{data['prev_year_total_amount'] >= 0 ? usdCurrencyFormat(data['prev_year_total_amount'] || 0) : `(${usdCurrencyFormat(Math.abs(data['prev_year_total_amount']))})`}</h4>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            {
                                                                                data['account_chart']['report_code'] === 'GT' && (
                                                                                    <h4 className={data['account_chart']['report_code'] + '-sum'}>{data['prev_year_total_amount'] >= 0 ? usdCurrencyFormat(data['prev_year_total_amount'] || 0) : `(${usdCurrencyFormat(Math.abs(data['prev_year_total_amount']))})`}</h4>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) ||
                                                            (
                                                                <>
                                                                    <div className="col-md-4 d-flex">
                                                                        <div className="col-md-3">
                                                                            <h4></h4>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                        <h4></h4>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                        <h4></h4>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                        <h4></h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 d-flex">
                                                                        <div className="col-md-3">
                                                                        <h4></h4>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                        <h4></h4>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                        <h4></h4>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                        <h4></h4>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }

                                                    </div>
                                                );
                                            }

                                        })
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className="text-center">
                                <span>Balance sheet is not available for the selected month and year</span>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>

    )
}

export default BalanceSheet;